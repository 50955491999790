@import '~antd/dist/antd.css';

.a\:s {
	align-content: stretch;
}
.a\:c {
	align-content: center;
}
.a\:f {
	align-content: flex-start;
}
.a\:f-end {
	align-content: flex-end;
}
.a\:s-between {
	align-content: space-between;
}
.a\:s-around {
	align-content: space-around;
}
.a\:i {
	align-content: initial;
}
.a\:in {
	align-content: inherit;
}
.a-i\:s {
	align-items: stretch;
}
.a-i\:c {
	align-items: center;
}
.a-i\:f {
	align-items: flex-start;
}
.a-i\:f-end {
	align-items: flex-end;
}
.a-i\:b {
	align-items: baseline;
}
.a-i\:i {
	align-items: initial;
}
.a-i\:in {
	align-items: inherit;
}
.a-s\:a {
	align-self: auto;
}
.a-s\:s {
	align-self: stretch;
}
.a-s\:c {
	align-self: center;
}
.a-s\:f {
	align-self: flex-start;
}
.a-s\:f-end {
	align-self: flex-end;
}
.a-s\:b {
	align-self: baseline;
}
.a-s\:i {
	align-self: initial;
}
.a-s\:in {
	align-self: inherit;
}
.an\:i {
	animation: initial;
}
.an\:in {
	animation: inherit;
}
.a-d\:i {
	animation-delay: initial;
}
.a-d\:in {
	animation-delay: inherit;
}
.a-di\:n {
	animation-direction: normal;
}
.a-di\:r {
	animation-direction: reverse;
}
.a-di\:a {
	animation-direction: alternate;
}
.a-di\:a-reverse {
	animation-direction: alternate-reverse;
}
.a-di\:i {
	animation-direction: initial;
}
.a-di\:in {
	animation-direction: inherit;
}
.a-du\:i {
	animation-duration: initial;
}
.a-du\:in {
	animation-duration: inherit;
}
.a-f-m\:n {
	animation-fill-mode: none;
}
.a-f-m\:f {
	animation-fill-mode: forwards;
}
.a-f-m\:b {
	animation-fill-mode: backwards;
}
.a-f-m\:bo {
	animation-fill-mode: both;
}
.a-f-m\:i {
	animation-fill-mode: initial;
}
.a-f-m\:in {
	animation-fill-mode: inherit;
}
.a-i-c\:i {
	animation-iteration-count: infinite;
}
.a-i-c\:in {
	animation-iteration-count: initial;
}
.a-i-c\:inh {
	animation-iteration-count: inherit;
}
.a-n\:n {
	animation-name: none;
}
.a-n\:i {
	animation-name: initial;
}
.a-n\:in {
	animation-name: inherit;
}
.a-p-s\:p {
	animation-play-state: paused;
}
.a-p-s\:r {
	animation-play-state: running;
}
.a-p-s\:i {
	animation-play-state: initial;
}
.a-p-s\:in {
	animation-play-state: inherit;
}
.a-t-f\:l {
	animation-timing-function: linear;
}
.a-t-f\:e {
	animation-timing-function: ease;
}
.a-t-f\:e-in {
	animation-timing-function: ease-in;
}
.a-t-f\:e-out {
	animation-timing-function: ease-out;
}
.a-t-f\:ea-i {
	animation-timing-function: ease-in-out;
}
.a-t-f\:s {
	animation-timing-function: step-start;
}
.a-t-f\:s-end {
	animation-timing-function: step-end;
}
.a-t-f\:i {
	animation-timing-function: initial;
}
.a-t-f\:in {
	animation-timing-function: inherit;
}
.b\:a {
	bottom: auto;
}
.b\:i {
	bottom: initial;
}
.b\:in {
	bottom: inherit;
}
.b-v\:v {
	backface-visibility: visible;
}
.b-v\:h {
	backface-visibility: hidden;
}
.b-v\:i {
	backface-visibility: initial;
}
.b-v\:in {
	backface-visibility: inherit;
}
.ba\:i {
	background: initial;
}
.ba\:in {
	background: inherit;
}
.b-a\:s {
	background-attachment: scroll;
}
.b-a\:f {
	background-attachment: fixed;
}
.b-a\:l {
	background-attachment: local;
}
.b-a\:i {
	background-attachment: initial;
}
.b-a\:in {
	background-attachment: inherit;
}
.b-c\:b {
	background-clip: border-box;
}
.b-c\:p {
	background-clip: padding-box;
}
.b-c\:c {
	background-clip: content-box;
}
.b-c\:i {
	background-clip: initial;
}
.b-c\:in {
	background-clip: inherit;
}
.b-co\:t {
	background-color: transparent;
}
.b-co\:i {
	background-color: initial;
}
.b-co\:in {
	background-color: inherit;
}
.b-i\:n {
	background-image: none;
}
.b-i\:i {
	background-image: initial;
}
.b-i\:in {
	background-image: inherit;
}
.b-o\:p {
	background-origin: padding-box;
}
.b-o\:b {
	background-origin: border-box;
}
.b-o\:c {
	background-origin: content-box;
}
.b-o\:i {
	background-origin: initial;
}
.b-o\:in {
	background-origin: inherit;
}
.b-p\:l {
	background-position: left top;
}
.b-p\:l-c {
	background-position: left center;
}
.b-p\:l-b {
	background-position: left bottom;
}
.b-p\:r {
	background-position: right top;
}
.b-p\:r-c {
	background-position: right center;
}
.b-p\:r-b {
	background-position: right bottom;
}
.b-p\:c {
	background-position: center top;
}
.b-p\:c-c {
	background-position: center center;
}
.b-p\:c-b {
	background-position: center bottom;
}
.b-p\:i {
	background-position: initial;
}
.b-p\:in {
	background-position: inherit;
}
.b-r\:r {
	background-repeat: repeat;
}
.b-r\:r-x {
	background-repeat: repeat-x;
}
.b-r\:r-y {
	background-repeat: repeat-y;
}
.b-r\:n {
	background-repeat: no-repeat;
}
.b-r\:s {
	background-repeat: space;
}
.b-r\:ro {
	background-repeat: round;
}
.b-r\:i {
	background-repeat: initial;
}
.b-r\:in {
	background-repeat: inherit;
}
.b-s\:a {
	background-size: auto;
}
.b-s\:c {
	background-size: cover;
}
.b-s\:co {
	background-size: contain;
}
.b-s\:i {
	background-size: initial;
}
.b-s\:in {
	background-size: inherit;
}
.bo\:i {
	border: initial;
}
.bo\:in {
	border: inherit;
}
.b-b\:i {
	border-bottom: initial;
}
.b-b\:in {
	border-bottom: inherit;
}
.b-b-c\:t {
	border-bottom-color: transparent;
}
.b-b-c\:i {
	border-bottom-color: initial;
}
.b-b-c\:in {
	border-bottom-color: inherit;
}
.b-b-l-r\:i {
	border-bottom-left-radius: initial;
}
.b-b-l-r\:in {
	border-bottom-left-radius: inherit;
}
.b-b-r-r\:i {
	border-bottom-right-radius: initial;
}
.b-b-r-r\:in {
	border-bottom-right-radius: inherit;
}
.b-b-s\:n {
	border-bottom-style: none;
}
.b-b-s\:h {
	border-bottom-style: hidden;
}
.b-b-s\:d {
	border-bottom-style: dotted;
}
.b-b-s\:da {
	border-bottom-style: dashed;
}
.b-b-s\:s {
	border-bottom-style: solid;
}
.b-b-s\:do {
	border-bottom-style: double;
}
.b-b-s\:g {
	border-bottom-style: groove;
}
.b-b-s\:r {
	border-bottom-style: ridge;
}
.b-b-s\:i {
	border-bottom-style: inset;
}
.b-b-s\:o {
	border-bottom-style: outset;
}
.b-b-s\:in {
	border-bottom-style: initial;
}
.b-b-s\:inh {
	border-bottom-style: inherit;
}
.b-b-w\:m {
	border-bottom-width: medium;
}
.b-b-w\:t {
	border-bottom-width: thin;
}
.b-b-w\:th {
	border-bottom-width: thick;
}
.b-b-w\:i {
	border-bottom-width: initial;
}
.b-b-w\:in {
	border-bottom-width: inherit;
}
.b-col\:s {
	border-collapse: separate;
}
.b-col\:c {
	border-collapse: collapse;
}
.b-col\:i {
	border-collapse: initial;
}
.b-col\:in {
	border-collapse: inherit;
}
.b-colo\:t {
	border-color: transparent;
}
.b-colo\:i {
	border-color: initial;
}
.b-colo\:in {
	border-color: inherit;
}
.b-im\:i {
	border-image: initial;
}
.b-im\:in {
	border-image: inherit;
}
.b-i-o\:i {
	border-image-outset: initial;
}
.b-i-o\:in {
	border-image-outset: inherit;
}
.b-i-r\:s {
	border-image-repeat: stretch;
}
.b-i-r\:r {
	border-image-repeat: repeat;
}
.b-i-r\:ro {
	border-image-repeat: round;
}
.b-i-r\:sp {
	border-image-repeat: space;
}
.b-i-r\:i {
	border-image-repeat: initial;
}
.b-i-r\:in {
	border-image-repeat: inherit;
}
.b-i-s\:f {
	border-image-slice: fill;
}
.b-i-s\:i {
	border-image-slice: initial;
}
.b-i-s\:in {
	border-image-slice: inherit;
}
.b-i-so\:n {
	border-image-source: none;
}
.b-i-so\:i {
	border-image-source: initial;
}
.b-i-so\:in {
	border-image-source: inherit;
}
.b-i-w\:a {
	border-image-width: auto;
}
.b-i-w\:i {
	border-image-width: initial;
}
.b-i-w\:in {
	border-image-width: inherit;
}
.b-l\:i {
	border-left: initial;
}
.b-l\:in {
	border-left: inherit;
}
.b-l-c\:t {
	border-left-color: transparent;
}
.b-l-c\:i {
	border-left-color: initial;
}
.b-l-c\:in {
	border-left-color: inherit;
}
.b-l-s\:n {
	border-left-style: none;
}
.b-l-s\:h {
	border-left-style: hidden;
}
.b-l-s\:d {
	border-left-style: dotted;
}
.b-l-s\:da {
	border-left-style: dashed;
}
.b-l-s\:s {
	border-left-style: solid;
}
.b-l-s\:do {
	border-left-style: double;
}
.b-l-s\:g {
	border-left-style: groove;
}
.b-l-s\:r {
	border-left-style: ridge;
}
.b-l-s\:i {
	border-left-style: inset;
}
.b-l-s\:o {
	border-left-style: outset;
}
.b-l-s\:in {
	border-left-style: initial;
}
.b-l-s\:inh {
	border-left-style: inherit;
}
.b-l-w\:m {
	border-left-width: medium;
}
.b-l-w\:t {
	border-left-width: thin;
}
.b-l-w\:th {
	border-left-width: thick;
}
.b-l-w\:i {
	border-left-width: initial;
}
.b-l-w\:in {
	border-left-width: inherit;
}
.b-ra\:i {
	border-radius: initial;
}
.b-ra\:in {
	border-radius: inherit;
}
.b-ri\:i {
	border-right: initial;
}
.b-ri\:in {
	border-right: inherit;
}
.b-r-c\:t {
	border-right-color: transparent;
}
.b-r-c\:i {
	border-right-color: initial;
}
.b-r-c\:in {
	border-right-color: inherit;
}
.b-r-s\:n {
	border-right-style: none;
}
.b-r-s\:h {
	border-right-style: hidden;
}
.b-r-s\:d {
	border-right-style: dotted;
}
.b-r-s\:da {
	border-right-style: dashed;
}
.b-r-s\:s {
	border-right-style: solid;
}
.b-r-s\:do {
	border-right-style: double;
}
.b-r-s\:g {
	border-right-style: groove;
}
.b-r-s\:r {
	border-right-style: ridge;
}
.b-r-s\:i {
	border-right-style: inset;
}
.b-r-s\:o {
	border-right-style: outset;
}
.b-r-s\:in {
	border-right-style: initial;
}
.b-r-s\:inh {
	border-right-style: inherit;
}
.b-r-w\:m {
	border-right-width: medium;
}
.b-r-w\:t {
	border-right-width: thin;
}
.b-r-w\:th {
	border-right-width: thick;
}
.b-r-w\:i {
	border-right-width: initial;
}
.b-r-w\:in {
	border-right-width: inherit;
}
.b-sp\:i {
	border-spacing: initial;
}
.b-sp\:in {
	border-spacing: inherit;
}
.b-st\:n {
	border-style: none;
}
.b-st\:h {
	border-style: hidden;
}
.b-st\:d {
	border-style: dotted;
}
.b-st\:da {
	border-style: dashed;
}
.b-st\:s {
	border-style: solid;
}
.b-st\:do {
	border-style: double;
}
.b-st\:g {
	border-style: groove;
}
.b-st\:r {
	border-style: ridge;
}
.b-st\:i {
	border-style: inset;
}
.b-st\:o {
	border-style: outset;
}
.b-st\:in {
	border-style: initial;
}
.b-st\:inh {
	border-style: inherit;
}
.b-t\:i {
	border-top: initial;
}
.b-t\:in {
	border-top: inherit;
}
.b-t-c\:t {
	border-top-color: transparent;
}
.b-t-c\:i {
	border-top-color: initial;
}
.b-t-c\:in {
	border-top-color: inherit;
}
.b-t-l-r\:i {
	border-top-left-radius: initial;
}
.b-t-l-r\:in {
	border-top-left-radius: inherit;
}
.b-t-r-r\:i {
	border-top-right-radius: initial;
}
.b-t-r-r\:in {
	border-top-right-radius: inherit;
}
.b-t-s\:n {
	border-top-style: none;
}
.b-t-s\:h {
	border-top-style: hidden;
}
.b-t-s\:d {
	border-top-style: dotted;
}
.b-t-s\:da {
	border-top-style: dashed;
}
.b-t-s\:s {
	border-top-style: solid;
}
.b-t-s\:do {
	border-top-style: double;
}
.b-t-s\:g {
	border-top-style: groove;
}
.b-t-s\:r {
	border-top-style: ridge;
}
.b-t-s\:i {
	border-top-style: inset;
}
.b-t-s\:o {
	border-top-style: outset;
}
.b-t-s\:in {
	border-top-style: initial;
}
.b-t-s\:inh {
	border-top-style: inherit;
}
.b-t-w\:m {
	border-top-width: medium;
}
.b-t-w\:t {
	border-top-width: thin;
}
.b-t-w\:th {
	border-top-width: thick;
}
.b-t-w\:i {
	border-top-width: initial;
}
.b-t-w\:in {
	border-top-width: inherit;
}
.b-w\:m {
	border-width: medium;
}
.b-w\:t {
	border-width: thin;
}
.b-w\:th {
	border-width: thick;
}
.b-w\:i {
	border-width: initial;
}
.b-w\:in {
	border-width: inherit;
}
.b-sh\:n {
	box-shadow: none;
}
.b-sh\:i {
	box-shadow: inset;
}
.b-sh\:in {
	box-shadow: initial;
}
.b-sh\:inh {
	box-shadow: inherit;
}
.b-si\:c {
	box-sizing: content-box;
}
.b-si\:b {
	box-sizing: border-box;
}
.b-si\:i {
	box-sizing: initial;
}
.b-si\:in {
	box-sizing: inherit;
}
.c\:a {
	cursor: alias;
}
.c\:a-scroll {
	cursor: all-scroll;
}
.c\:au {
	cursor: auto;
}
.c\:c {
	cursor: cell;
}
.c\:c-menu {
	cursor: context-menu;
}
.c\:c-resize {
	cursor: col-resize;
}
.c\:co {
	cursor: copy;
}
.c\:cr {
	cursor: crosshair;
}
.c\:d {
	cursor: default;
}
.c\:e {
	cursor: e-resize;
}
.c\:e-resize {
	cursor: ew-resize;
}
.c\:g {
	cursor: grab;
}
.c\:gr {
	cursor: grabbing;
}
.c\:h {
	cursor: help;
}
.c\:m {
	cursor: move;
}
.c\:n {
	cursor: n-resize;
}
.c\:n-resize {
	cursor: ne-resize;
}
.c\:ne {
	cursor: nesw-resize;
}
.c\:ns {
	cursor: ns-resize;
}
.c\:nw {
	cursor: nw-resize;
}
.c\:nw-resize {
	cursor: nwse-resize;
}
.c\:n-drop {
	cursor: no-drop;
}
.c\:no {
	cursor: none;
}
.c\:no-allowed {
	cursor: not-allowed;
}
.c\:p {
	cursor: pointer;
}
.c\:pr {
	cursor: progress;
}
.c\:r {
	cursor: row-resize;
}
.c\:s {
	cursor: s-resize;
}
.c\:s-resize {
	cursor: se-resize;
}
.c\:sw {
	cursor: sw-resize;
}
.c\:t {
	cursor: text;
}
.c\:v {
	cursor: vertical-text;
}
.c\:w {
	cursor: w-resize;
}
.c\:wa {
	cursor: wait;
}
.c\:z {
	cursor: zoom-in;
}
.c\:z-out {
	cursor: zoom-out;
}
.c\:i {
	cursor: initial;
}
.c\:in {
	cursor: inherit;
}
.c-s\:t {
	caption-side: top;
}
.c-s\:b {
	caption-side: bottom;
}
.c-s\:i {
	caption-side: initial;
}
.c-s\:in {
	caption-side: inherit;
}
.cl\:n {
	clear: none;
}
.cl\:l {
	clear: left;
}
.cl\:r {
	clear: right;
}
.cl\:b {
	clear: both;
}
.cl\:i {
	clear: initial;
}
.cl\:in {
	clear: inherit;
}
.cli\:a {
	clip: auto;
}
.cli\:i {
	clip: initial;
}
.cli\:in {
	clip: inherit;
}
.c-p\:c {
	clip-path: clip-source;
}
.c-p\:m {
	clip-path: margin-box;
}
.c-p\:b {
	clip-path: border-box;
}
.c-p\:p {
	clip-path: padding-box;
}
.c-p\:c-box {
	clip-path: content-box;
}
.c-p\:f {
	clip-path: fill-box;
}
.c-p\:s {
	clip-path: stroke-box;
}
.c-p\:v {
	clip-path: view-box;
}
.c-p\:n {
	clip-path: none;
}
.co\:i {
	color: initial;
}
.co\:in {
	color: inherit;
}
.c-c\:a {
	column-count: auto;
}
.c-c\:i {
	column-count: initial;
}
.c-c\:in {
	column-count: inherit;
}
.c-f\:b {
	column-fill: balance;
}
.c-f\:a {
	column-fill: auto;
}
.c-f\:i {
	column-fill: initial;
}
.c-f\:in {
	column-fill: inherit;
}
.c-g\:n {
	column-gap: normal;
}
.c-g\:i {
	column-gap: initial;
}
.c-g\:in {
	column-gap: inherit;
}
.c-r\:i {
	column-rule: initial;
}
.c-r\:in {
	column-rule: inherit;
}
.c-r-c\:i {
	column-rule-color: initial;
}
.c-r-c\:in {
	column-rule-color: inherit;
}
.c-r-s\:n {
	column-rule-style: none;
}
.c-r-s\:h {
	column-rule-style: hidden;
}
.c-r-s\:d {
	column-rule-style: dotted;
}
.c-r-s\:da {
	column-rule-style: dashed;
}
.c-r-s\:s {
	column-rule-style: solid;
}
.c-r-s\:do {
	column-rule-style: double;
}
.c-r-s\:g {
	column-rule-style: groove;
}
.c-r-s\:r {
	column-rule-style: ridge;
}
.c-r-s\:i {
	column-rule-style: inset;
}
.c-r-s\:o {
	column-rule-style: outset;
}
.c-r-s\:in {
	column-rule-style: initial;
}
.c-r-s\:inh {
	column-rule-style: inherit;
}
.c-r-w\:m {
	column-rule-width: medium;
}
.c-r-w\:t {
	column-rule-width: thin;
}
.c-r-w\:th {
	column-rule-width: thick;
}
.c-r-w\:i {
	column-rule-width: initial;
}
.c-r-w\:in {
	column-rule-width: inherit;
}
.c-sp\:n {
	column-span: none;
}
.c-sp\:a {
	column-span: all;
}
.c-sp\:i {
	column-span: initial;
}
.c-sp\:in {
	column-span: inherit;
}
.c-w\:a {
	column-width: auto;
}
.c-w\:i {
	column-width: initial;
}
.c-w\:in {
	column-width: inherit;
}
.col\:a {
	columns: auto;
}
.col\:i {
	columns: initial;
}
.col\:in {
	columns: inherit;
}
.con\:n {
	content: normal;
}
.con\:no {
	content: none;
}
.con\:c {
	content: counter;
}
.con\:o {
	content: open-quote;
}
.con\:c-quote {
	content: close-quote;
}
.con\:n-quote {
	content: no-open-quote;
}
.con\:no-c {
	content: no-close-quote;
}
.con\:i {
	content: initial;
}
.con\:in {
	content: inherit;
}
.c-i\:n {
	counter-increment: none;
}
.c-i\:i {
	counter-increment: initial;
}
.c-i\:in {
	counter-increment: inherit;
}
.c-re\:n {
	counter-reset: none;
}
.c-re\:i {
	counter-reset: initial;
}
.c-re\:in {
	counter-reset: inherit;
}
.d\:i {
	display: inline;
}
.d\:b {
	display: block;
}
.d\:c {
	display: contents;
}
.d\:f {
	display: flex;
}
.d\:g {
	display: grid;
}
.d\:i-block {
	display: inline-block;
}
.d\:i-flex {
	display: inline-flex;
}
.d\:i-grid {
	display: inline-grid;
}
.d\:i-table {
	display: inline-table;
}
.d\:l {
	display: list-item;
}
.d\:r {
	display: run-in;
}
.d\:t {
	display: table;
}
.d\:t-caption {
	display: table-caption;
}
.d\:t-group {
	display: table-column-group;
}
.d\:ta-h {
	display: table-header-group;
}
.d\:ta-f {
	display: table-footer-group;
}
.d\:ta-r {
	display: table-row-group;
}
.d\:t-cell {
	display: table-cell;
}
.d\:t-column {
	display: table-column;
}
.d\:t-row {
	display: table-row;
}
.d\:n {
	display: none;
}
.d\:in {
	display: initial;
}
.d\:inh {
	display: inherit;
}
.di\:l {
	direction: ltr;
}
.di\:r {
	direction: rtl;
}
.di\:i {
	direction: initial;
}
.di\:in {
	direction: inherit;
}
.e\:s {
	empty-cells: show;
}
.e\:h {
	empty-cells: hide;
}
.e\:i {
	empty-cells: initial;
}
.e\:in {
	empty-cells: inherit;
}
.f\:n {
	float: none;
}
.f\:l {
	float: left;
}
.f\:r {
	float: right;
}
.f\:i {
	float: initial;
}
.f\:in {
	float: inherit;
}
.fo\:c {
	font: caption;
}
.fo\:i {
	font: icon;
}
.fo\:m {
	font: menu;
}
.fo\:m-box {
	font: message-box;
}
.fo\:s {
	font: small-caption;
}
.fo\:s-bar {
	font: status-bar;
}
.fo\:in {
	font: initial;
}
.fo\:inh {
	font: inherit;
}
.f-f\:i {
	font-family: initial;
}
.f-f\:in {
	font-family: inherit;
}
.f-k\:a {
	font-kerning: auto;
}
.f-k\:n {
	font-kerning: normal;
}
.f-k\:no {
	font-kerning: none;
}
.f-s\:m {
	font-size: medium;
}
.f-s\:x {
	font-size: xx-small;
}
.f-s\:x-small {
	font-size: x-small;
}
.f-s\:s {
	font-size: small;
}
.f-s\:l {
	font-size: large;
}
.f-s\:x-large {
	font-size: x-large;
}
.f-s\:xx {
	font-size: xx-large;
}
.f-s\:sm {
	font-size: smaller;
}
.f-s\:la {
	font-size: larger;
}
.f-s\:i {
	font-size: initial;
}
.f-s\:in {
	font-size: inherit;
}
.f-st\:u {
	font-stretch: ultra-condensed;
}
.f-st\:e {
	font-stretch: extra-condensed;
}
.f-st\:c {
	font-stretch: condensed;
}
.f-st\:s {
	font-stretch: semi-condensed;
}
.f-st\:n {
	font-stretch: normal;
}
.f-st\:s-expanded {
	font-stretch: semi-expanded;
}
.f-st\:ex {
	font-stretch: expanded;
}
.f-st\:ex-expanded {
	font-stretch: extra-expanded;
}
.f-st\:u-expanded {
	font-stretch: ultra-expanded;
}
.f-st\:i {
	font-stretch: initial;
}
.f-st\:in {
	font-stretch: inherit;
}
.f-sty\:n {
	font-style: normal;
}
.f-sty\:i {
	font-style: italic;
}
.f-sty\:o {
	font-style: oblique;
}
.f-sty\:in {
	font-style: initial;
}
.f-sty\:inh {
	font-style: inherit;
}
.f-v\:n {
	font-variant: normal;
}
.f-v\:s {
	font-variant: small-caps;
}
.f-v\:i {
	font-variant: initial;
}
.f-v\:in {
	font-variant: inherit;
}
.f-w\:n {
	font-weight: 400;
}
.f-w\:b {
	font-weight: 700;
}
.f-w\:bo {
	font-weight: bolder;
}
.f-w\:l {
	font-weight: lighter;
}
.f-w\:100 {
	font-weight: 100;
}
.f-w\:200 {
	font-weight: 200;
}
.f-w\:300 {
	font-weight: 300;
}
.f-w\:400 {
	font-weight: 400;
}
.f-w\:500 {
	font-weight: 500;
}
.f-w\:600 {
	font-weight: 600;
}
.f-w\:700 {
	font-weight: 700;
}
.f-w\:800 {
	font-weight: 800;
}
.f-w\:900 {
	font-weight: 900;
}
.f-w\:i {
	font-weight: initial;
}
.f-w\:in {
	font-weight: inherit;
}
.fl\:a {
	flex: auto;
}
.fl\:i {
	flex: initial;
}
.fl\:n {
	flex: none;
}
.fl\:in {
	flex: inherit;
}
.f-b\:a {
	flex-basis: auto;
}
.f-b\:i {
	flex-basis: initial;
}
.f-b\:in {
	flex-basis: inherit;
}
.f-d\:r {
	flex-direction: row;
}
.f-d\:r-reverse {
	flex-direction: row-reverse;
}
.f-d\:c {
	flex-direction: column;
}
.f-d\:c-reverse {
	flex-direction: column-reverse;
}
.f-d\:i {
	flex-direction: initial;
}
.f-d\:in {
	flex-direction: inherit;
}
.f-fl\:f {
	flex-flow: flex-direction;
}
.f-fl\:f-wrap {
	flex-flow: flex-wrap;
}
.f-fl\:i {
	flex-flow: initial;
}
.f-fl\:in {
	flex-flow: inherit;
}
.f-g\:i {
	flex-grow: initial;
}
.f-g\:in {
	flex-grow: inherit;
}
.f-sh\:i {
	flex-shrink: initial;
}
.f-sh\:in {
	flex-shrink: inherit;
}
.f-wr\:n {
	flex-wrap: nowrap;
}
.f-wr\:w {
	flex-wrap: wrap;
}
.f-wr\:w-reverse {
	flex-wrap: wrap-reverse;
}
.f-wr\:i {
	flex-wrap: initial;
}
.f-wr\:in {
	flex-wrap: inherit;
}
.g\:n {
	grid: none;
}
.g\:i {
	grid: initial;
}
.g\:in {
	grid: inherit;
}
.g-a-c\:a {
	grid-auto-columns: auto;
}
.g-a-c\:m {
	grid-auto-columns: max-content;
}
.g-a-c\:m-content {
	grid-auto-columns: min-content;
}
.g-a-f\:r {
	grid-auto-flow: row;
}
.g-a-f\:c {
	grid-auto-flow: column;
}
.g-a-f\:d {
	grid-auto-flow: dense;
}
.g-a-f\:r-d {
	grid-auto-flow: row dense;
}
.g-a-f\:c-d {
	grid-auto-flow: column dense;
}
.g-a-r\:a {
	grid-auto-rows: auto;
}
.g-a-r\:m {
	grid-auto-rows: max-content;
}
.g-a-r\:m-content {
	grid-auto-rows: min-content;
}
.g-c-e\:a {
	grid-column-end: auto;
}
.g-c-s\:a {
	grid-column-start: auto;
}
.g-g\:g {
	grid-gap: grid-row-gap;
}
.g-g\:g-gap {
	grid-gap: grid-column-gap;
}
.g-r-e\:a {
	grid-row-end: auto;
}
.g-r-s\:a {
	grid-row-start: auto;
}
.g-t\:n {
	grid-template: none;
}
.g-t\:i {
	grid-template: initial;
}
.g-t\:in {
	grid-template: inherit;
}
.g-t-a\:n {
	grid-template-areas: none;
}
.g-t-c\:n {
	grid-template-columns: none;
}
.g-t-c\:a {
	grid-template-columns: auto;
}
.g-t-c\:m {
	grid-template-columns: max-content;
}
.g-t-c\:m-content {
	grid-template-columns: min-content;
}
.g-t-c\:i {
	grid-template-columns: initial;
}
.g-t-c\:in {
	grid-template-columns: inherit;
}
.g-t-r\:n {
	grid-template-rows: none;
}
.g-t-r\:a {
	grid-template-rows: auto;
}
.g-t-r\:m {
	grid-template-rows: max-content;
}
.g-t-r\:m-content {
	grid-template-rows: min-content;
}
.h\:a {
	height: auto;
}
.h\:i {
	height: initial;
}
.h\:in {
	height: inherit;
}
.hy\:n {
	hyphens: none;
}
.hy\:m {
	hyphens: manual;
}
.hy\:a {
	hyphens: auto;
}
.hy\:i {
	hyphens: initial;
}
.hy\:in {
	hyphens: inherit;
}
.i\:u {
	import: url;
}
.i\:l {
	import: list-of-mediaqueries;
}
.j\:f {
	justify-content: flex-start;
}
.j\:f-end {
	justify-content: flex-end;
}
.j\:c {
	justify-content: center;
}
.j\:s {
	justify-content: space-between;
}
.j\:s-around {
	justify-content: space-around;
}
.j\:i {
	justify-content: initial;
}
.j\:in {
	justify-content: inherit;
}
.l\:a {
	left: auto;
}
.l\:i {
	left: initial;
}
.l\:in {
	left: inherit;
}
.l-s\:n {
	letter-spacing: normal;
}
.l-s\:i {
	letter-spacing: initial;
}
.l-s\:in {
	letter-spacing: inherit;
}
.l-h\:n {
	line-height: normal;
}
.l-h\:i {
	line-height: initial;
}
.l-h\:in {
	line-height: inherit;
}
.l-st\:i {
	list-style: initial;
}
.l-st\:in {
	list-style: inherit;
}
.l-s-i\:n {
	list-style-image: none;
}
.l-s-i\:u {
	list-style-image: url;
}
.l-s-i\:i {
	list-style-image: initial;
}
.l-s-i\:in {
	list-style-image: inherit;
}
.l-s-p\:i {
	list-style-position: inside;
}
.l-s-p\:o {
	list-style-position: outside;
}
.l-s-p\:in {
	list-style-position: initial;
}
.l-s-p\:inh {
	list-style-position: inherit;
}
.l-s-t\:d {
	list-style-type: disc;
}
.l-s-t\:a {
	list-style-type: armenian;
}
.l-s-t\:c {
	list-style-type: circle;
}
.l-s-t\:c-ideographic {
	list-style-type: cjk-ideographic;
}
.l-s-t\:de {
	list-style-type: decimal;
}
.l-s-t\:d-zero {
	list-style-type: decimal-leading-zero;
}
.l-s-t\:g {
	list-style-type: georgian;
}
.l-s-t\:h {
	list-style-type: hebrew;
}
.l-s-t\:hi {
	list-style-type: hiragana;
}
.l-s-t\:hi-iroha {
	list-style-type: hiragana-iroha;
}
.l-s-t\:k {
	list-style-type: katakana;
}
.l-s-t\:k-iroha {
	list-style-type: katakana-iroha;
}
.l-s-t\:l {
	list-style-type: lower-alpha;
}
.l-s-t\:l-greek {
	list-style-type: lower-greek;
}
.l-s-t\:l-latin {
	list-style-type: lower-latin;
}
.l-s-t\:l-roman {
	list-style-type: lower-roman;
}
.l-s-t\:n {
	list-style-type: none;
}
.l-s-t\:s {
	list-style-type: square;
}
.l-s-t\:u {
	list-style-type: upper-alpha;
}
.l-s-t\:u-greek {
	list-style-type: upper-greek;
}
.l-s-t\:u-latin {
	list-style-type: upper-latin;
}
.l-s-t\:u-roman {
	list-style-type: upper-roman;
}
.l-s-t\:i {
	list-style-type: initial;
}
.l-s-t\:in {
	list-style-type: inherit;
}
.m\:a {
	margin: auto;
}
.m\:i {
	margin: initial;
}
.m\:in {
	margin: inherit;
}
.m-b\:a {
	margin-bottom: auto;
}
.m-b\:i {
	margin-bottom: initial;
}
.m-b\:in {
	margin-bottom: inherit;
}
.m-l\:a {
	margin-left: auto;
}
.m-l\:i {
	margin-left: initial;
}
.m-l\:in {
	margin-left: inherit;
}
.m-r\:a {
	margin-right: auto;
}
.m-r\:i {
	margin-right: initial;
}
.m-r\:in {
	margin-right: inherit;
}
.m-t\:a {
	margin-top: auto;
}
.m-t\:i {
	margin-top: initial;
}
.m-t\:in {
	margin-top: inherit;
}
.m-h\:n {
	max-height: none;
}
.m-h\:i {
	max-height: initial;
}
.m-h\:in {
	max-height: inherit;
}
.m-w\:n {
	max-width: none;
}
.m-w\:i {
	max-width: initial;
}
.m-w\:in {
	max-width: inherit;
}
.me\:a {
	media: all;
}
.me\:p {
	media: print;
}
.me\:s {
	media: screen;
}
.me\:sp {
	media: speech;
}
.me\:a-hover {
	media: any-hover;
}
.me\:a-pointer {
	media: any-pointer;
}
.me\:a-ratio {
	media: aspect-ratio;
}
.me\:c {
	media: color;
}
.me\:c-gamut {
	media: color-gamut;
}
.me\:c-index {
	media: color-index;
}
.me\:g {
	media: grid;
}
.me\:h {
	media: height;
}
.me\:ho {
	media: hover;
}
.me\:i {
	media: inverted-colors;
}
.me\:l {
	media: light-level;
}
.me\:m {
	media: max-aspect-ratio;
}
.me\:m-color {
	media: max-color;
}
.me\:m-index {
	media: max-color-index;
}
.me\:m-height {
	media: max-height;
}
.me\:m-monochrome {
	media: max-monochrome;
}
.me\:m-resolution {
	media: max-resolution;
}
.me\:m-width {
	media: max-width;
}
.me\:m-ratio {
	media: min-aspect-ratio;
}
.me\:mi {
	media: min-color;
}
.me\:mi-c {
	media: min-color-index;
}
.me\:mi-height {
	media: min-height;
}
.me\:mi-monochrome {
	media: min-monochrome;
}
.me\:mi-resolution {
	media: min-resolution;
}
.me\:mi-width {
	media: min-width;
}
.me\:mo {
	media: monochrome;
}
.me\:o {
	media: orientation;
}
.me\:o-block {
	media: overflow-block;
}
.me\:o-inline {
	media: overflow-inline;
}
.me\:po {
	media: pointer;
}
.me\:r {
	media: resolution;
}
.me\:sc {
	media: scan;
}
.me\:scr {
	media: scripting;
}
.me\:u {
	media: update;
}
.me\:w {
	media: width;
}
.m-he\:i {
	min-height: initial;
}
.m-he\:in {
	min-height: inherit;
}
.m-wi\:i {
	min-width: initial;
}
.m-wi\:in {
	min-width: inherit;
}
.o\:v {
	overflow: visible;
}
.o\:h {
	overflow: hidden;
}
.o\:s {
	overflow: scroll;
}
.o\:a {
	overflow: auto;
}
.o\:i {
	overflow: initial;
}
.o\:in {
	overflow: inherit;
}
.o-f\:f {
	object-fit: fill;
}
.o-f\:c {
	object-fit: contain;
}
.o-f\:co {
	object-fit: cover;
}
.o-f\:n {
	object-fit: none;
}
.o-f\:s {
	object-fit: scale-down;
}
.o-f\:i {
	object-fit: initial;
}
.o-f\:in {
	object-fit: inherit;
}
.o-p\:p {
	object-position: position;
}
.o-p\:i {
	object-position: initial;
}
.o-p\:in {
	object-position: inherit;
}
.op\:i {
	opacity: initial;
}
.op\:in {
	opacity: inherit;
}
.or\:i {
	order: initial;
}
.or\:in {
	order: inherit;
}
.ou\:i {
	outline: initial;
}
.ou\:in {
	outline: inherit;
}
.o-c\:i {
	outline-color: invert;
}
.o-c\:in {
	outline-color: initial;
}
.o-c\:inh {
	outline-color: inherit;
}
.o-o\:i {
	outline-offset: initial;
}
.o-o\:in {
	outline-offset: inherit;
}
.o-s\:n {
	outline-style: none;
}
.o-s\:h {
	outline-style: hidden;
}
.o-s\:d {
	outline-style: dotted;
}
.o-s\:da {
	outline-style: dashed;
}
.o-s\:s {
	outline-style: solid;
}
.o-s\:do {
	outline-style: double;
}
.o-s\:g {
	outline-style: groove;
}
.o-s\:r {
	outline-style: ridge;
}
.o-s\:i {
	outline-style: inset;
}
.o-s\:o {
	outline-style: outset;
}
.o-s\:in {
	outline-style: initial;
}
.o-s\:inh {
	outline-style: inherit;
}
.o-w\:m {
	outline-width: medium;
}
.o-w\:t {
	outline-width: thin;
}
.o-w\:th {
	outline-width: thick;
}
.o-w\:i {
	outline-width: initial;
}
.o-w\:in {
	outline-width: inherit;
}
.o-x\:v {
	overflow-x: visible;
}
.o-x\:h {
	overflow-x: hidden;
}
.o-x\:s {
	overflow-x: scroll;
}
.o-x\:a {
	overflow-x: auto;
}
.o-x\:i {
	overflow-x: initial;
}
.o-x\:in {
	overflow-x: inherit;
}
.o-y\:v {
	overflow-y: visible;
}
.o-y\:h {
	overflow-y: hidden;
}
.o-y\:s {
	overflow-y: scroll;
}
.o-y\:a {
	overflow-y: auto;
}
.o-y\:i {
	overflow-y: initial;
}
.o-y\:in {
	overflow-y: inherit;
}
.p\:s {
	position: static;
}
.p\:a {
	position: absolute;
}
.p\:f {
	position: fixed;
}
.p\:r {
	position: relative;
}
.p\:st {
	position: sticky;
}
.p\:i {
	position: initial;
}
.p\:in {
	position: inherit;
}
.pa\:i {
	padding: initial;
}
.pa\:in {
	padding: inherit;
}
.p-b\:i {
	padding-bottom: initial;
}
.p-b\:in {
	padding-bottom: inherit;
}
.p-l\:i {
	padding-left: initial;
}
.p-l\:in {
	padding-left: inherit;
}
.p-r\:i {
	padding-right: initial;
}
.p-r\:in {
	padding-right: inherit;
}
.p-t\:i {
	padding-top: initial;
}
.p-t\:in {
	padding-top: inherit;
}
.p-b-a\:a {
	page-break-after: auto;
}
.p-b-a\:al {
	page-break-after: always;
}
.p-b-a\:av {
	page-break-after: avoid;
}
.p-b-a\:l {
	page-break-after: left;
}
.p-b-a\:r {
	page-break-after: right;
}
.p-b-a\:i {
	page-break-after: initial;
}
.p-b-a\:in {
	page-break-after: inherit;
}
.p-b-b\:a {
	page-break-before: auto;
}
.p-b-b\:al {
	page-break-before: always;
}
.p-b-b\:av {
	page-break-before: avoid;
}
.p-b-b\:l {
	page-break-before: left;
}
.p-b-b\:r {
	page-break-before: right;
}
.p-b-b\:i {
	page-break-before: initial;
}
.p-b-b\:in {
	page-break-before: inherit;
}
.p-b-i\:a {
	page-break-inside: auto;
}
.p-b-i\:av {
	page-break-inside: avoid;
}
.p-b-i\:i {
	page-break-inside: initial;
}
.p-b-i\:in {
	page-break-inside: inherit;
}
.pe\:n {
	perspective: none;
}
.pe\:i {
	perspective: initial;
}
.pe\:in {
	perspective: inherit;
}
.p-o\:i {
	perspective-origin: initial;
}
.p-o\:in {
	perspective-origin: inherit;
}
.p-e\:a {
	pointer-events: auto;
}
.p-e\:n {
	pointer-events: none;
}
.p-e\:i {
	pointer-events: initial;
}
.p-e\:in {
	pointer-events: inherit;
}
.q\:n {
	quotes: none;
}
.q\:i {
	quotes: initial;
}
.q\:in {
	quotes: inherit;
}
.r\:a {
	right: auto;
}
.r\:i {
	right: initial;
}
.r\:in {
	right: inherit;
}
.t\:a {
	top: auto;
}
.t\:i {
	top: initial;
}
.t\:in {
	top: inherit;
}
.t-l\:a {
	table-layout: auto;
}
.t-l\:f {
	table-layout: fixed;
}
.t-l\:i {
	table-layout: initial;
}
.t-l\:in {
	table-layout: inherit;
}
.t-a\:l {
	text-align: left;
}
.t-a\:r {
	text-align: right;
}
.t-a\:c {
	text-align: center;
}
.t-a\:j {
	text-align: justify;
}
.t-a\:i {
	text-align: initial;
}
.t-a\:in {
	text-align: inherit;
}
.t-d\:i {
	text-decoration: initial;
}
.t-d\:in {
	text-decoration: inherit;
}
.t-i\:i {
	text-indent: initial;
}
.t-i\:in {
	text-indent: inherit;
}
.t-j\:a {
	text-justify: auto;
}
.t-j\:i {
	text-justify: inter-word;
}
.t-j\:i-character {
	text-justify: inter-character;
}
.t-j\:n {
	text-justify: none;
}
.t-j\:in {
	text-justify: initial;
}
.t-j\:inh {
	text-justify: inherit;
}
.t-o\:c {
	text-overflow: clip;
}
.t-o\:e {
	text-overflow: ellipsis;
}
.t-o\:i {
	text-overflow: initial;
}
.t-o\:in {
	text-overflow: inherit;
}
.t-s\:n {
	text-shadow: none;
}
.t-s\:i {
	text-shadow: initial;
}
.t-s\:in {
	text-shadow: inherit;
}
.t-t\:n {
	text-transform: none;
}
.t-t\:c {
	text-transform: capitalize;
}
.t-t\:u {
	text-transform: uppercase;
}
.t-t\:l {
	text-transform: lowercase;
}
.t-t\:i {
	text-transform: initial;
}
.t-t\:in {
	text-transform: inherit;
}
.tr\:n {
	transform: none;
}
.tr\:i {
	transform: initial;
}
.tr\:in {
	transform: inherit;
}
.t-or\:i {
	transform-origin: initial;
}
.t-or\:in {
	transform-origin: inherit;
}
.t-st\:f {
	transform-style: flat;
}
.t-st\:p {
	transform-style: preserve-3d;
}
.t-st\:i {
	transform-style: initial;
}
.t-st\:in {
	transform-style: inherit;
}
.tra\:i {
	transition: initial;
}
.tra\:in {
	transition: inherit;
}
.t-de\:i {
	transition-delay: initial;
}
.t-de\:in {
	transition-delay: inherit;
}
.t-du\:i {
	transition-duration: initial;
}
.t-du\:in {
	transition-duration: inherit;
}
.t-p\:n {
	transition-property: none;
}
.t-p\:a {
	transition-property: all;
}
.t-p\:i {
	transition-property: initial;
}
.t-p\:in {
	transition-property: inherit;
}
.t-t-f\:e {
	transition-timing-function: ease;
}
.t-t-f\:l {
	transition-timing-function: linear;
}
.t-t-f\:e-in {
	transition-timing-function: ease-in;
}
.t-t-f\:e-out {
	transition-timing-function: ease-out;
}
.t-t-f\:ea-i {
	transition-timing-function: ease-in-out;
}
.t-t-f\:s {
	transition-timing-function: step-start;
}
.t-t-f\:s-end {
	transition-timing-function: step-end;
}
.t-t-f\:i {
	transition-timing-function: initial;
}
.t-t-f\:in {
	transition-timing-function: inherit;
}
.u\:a {
	user-select: auto;
}
.u\:n {
	user-select: none;
}
.u\:t {
	user-select: text;
}
.u\:al {
	user-select: all;
}
.u-b\:n {
	unicode-bidi: normal;
}
.u-b\:e {
	unicode-bidi: embed;
}
.u-b\:b {
	unicode-bidi: bidi-override;
}
.u-b\:i {
	unicode-bidi: isolate;
}
.u-b\:i-override {
	unicode-bidi: isolate-override;
}
.u-b\:p {
	unicode-bidi: plaintext;
}
.u-b\:in {
	unicode-bidi: initial;
}
.u-b\:inh {
	unicode-bidi: inherit;
}
.v\:b {
	vertical-align: baseline;
}
.v\:s {
	vertical-align: sub;
}
.v\:su {
	vertical-align: super;
}
.v\:t {
	vertical-align: top;
}
.v\:t-top {
	vertical-align: text-top;
}
.v\:m {
	vertical-align: middle;
}
.v\:bo {
	vertical-align: bottom;
}
.v\:t-bottom {
	vertical-align: text-bottom;
}
.v\:i {
	vertical-align: initial;
}
.v\:in {
	vertical-align: inherit;
}
.vi\:v {
	visibility: visible;
}
.vi\:h {
	visibility: hidden;
}
.vi\:c {
	visibility: collapse;
}
.vi\:i {
	visibility: initial;
}
.vi\:in {
	visibility: inherit;
}
.w\:a {
	width: auto;
}
.w\:i {
	width: initial;
}
.w\:in {
	width: inherit;
}
.w-s\:n {
	white-space: normal;
}
.w-s\:no {
	white-space: nowrap;
}
.w-s\:p {
	white-space: pre;
}
.w-s\:p-line {
	white-space: pre-line;
}
.w-s\:p-wrap {
	white-space: pre-wrap;
}
.w-s\:i {
	white-space: initial;
}
.w-s\:in {
	white-space: inherit;
}
.w-b\:n {
	word-break: normal;
}
.w-b\:b {
	word-break: break-all;
}
.w-b\:k {
	word-break: keep-all;
}
.w-b\:b-word {
	word-break: break-word;
}
.w-b\:i {
	word-break: initial;
}
.w-b\:in {
	word-break: inherit;
}
.w-sp\:n {
	word-spacing: normal;
}
.w-sp\:i {
	word-spacing: initial;
}
.w-sp\:in {
	word-spacing: inherit;
}
.w-w\:n {
	word-wrap: normal;
}
.w-w\:b {
	word-wrap: break-word;
}
.w-w\:i {
	word-wrap: initial;
}
.w-w\:in {
	word-wrap: inherit;
}
.w-m\:h {
	writing-mode: horizontal-tb;
}
.w-m\:v {
	writing-mode: vertical-rl;
}
.w-m\:v-lr {
	writing-mode: vertical-lr;
}
.z\:a {
	z-index: auto;
}
.z\:i {
	z-index: initial;
}
.z\:in {
	z-index: inherit;
}

/* custom CSS classes */

.mCenter {
	margin: auto;
}

.fCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}

.marginLeft-24 {
	margin-left: 24px !important;
}

.marginTop-48 {
	margin-top: 40px !important;
}

.marginTop-24 {
	margin-top: 24px !important;
}

.marginTop-12 {
	margin-top: 12px !important;
}

.marginTop-6 {
	margin-top: 6px !important;
}
