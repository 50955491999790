.gameBoard-info p {
  margin-bottom: 10px;
}
.gameBoard-info p b {
  font-size: 1.1em;
}
.gameBoard-info p .off {
  color: red;
}
.gameBoard-info p .on {
  color: green;
}
.gameBoard-navBar {
  width: 100%;
  position: fixed;
  background-color: rgba(237, 238, 236, 0.85);
  backdrop-filter: blur(7px);
  z-index: 1;
  left: 0;
  border-radius: 6px;
  margin-top: 2px;
}
.deckHeading {
  font-size: 20px;
  font-weight: bold;
}
.helpIcon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  background-image: url('images/help.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}
.scoreBreakdownLink {
  color: #1890ff;
  font-size: 11px;
}
.specialAbilitiesList {
  position: absolute;
  color: #232b2b;
  height: 100%;
  border-radius: 12px;
  z-index: 10;
}
.specialAbilitiesList p {
  background-color: #36454f;
  color: white;
  border-radius: 2px;
  font-weight: bolder;
  font-size: 12px;
  padding-left: 3px;
  padding-right: 3px;
  text-align: center;
}
.specialAbilitiesList div.specialAbilityToApply {
  color: rosybrown;
}
.specialAbilitiesList div.specialAbilityToApply.alreadyUsed {
  color: #838383;
}
.specialAbilitiesList div.specialAbilityToApply.alreadyDestroyed {
  color: #c04000;
}
.milestoneModalIcon,
.trophyModalIcon {
  width: 50px;
  text-align: center;
  display: block;
  margin: auto;
}
.milestoneModal .ant-modal-confirm-title,
.trophyModal .ant-modal-confirm-title {
  font-size: 20px !important;
  font-weight: bolder !important;
  margin-top: 12px !important;
  text-align: center !important;
}
.milestoneModal .ant-modal-confirm-content,
.trophyModal .ant-modal-confirm-content {
  text-align: center;
}
.milestoneModal .ant-modal-confirm-btns,
.trophyModal .ant-modal-confirm-btns {
  text-align: center;
  margin: auto;
  display: block;
  width: 100%;
  margin-top: 24px;
}
.trophyModalIcon {
  width: 200px;
}
@media only screen and (min-width: 1214px) {
  .gameBoard-navBar {
    max-width: 1214px;
    left: auto;
  }
}
