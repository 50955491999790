.card-container {
  margin: auto;
  position: relative;
  width: 200px;
}
.card {
  background-color: whitesmoke;
  border-radius: 12px;
  height: 350px;
  overflow: auto;
  transition: 0.5s ease-in-out;
  user-select: none;
  width: 200px;
}
.card-content,
.card-content-back,
.card-content-front {
  margin: 5px;
}
.card.selected {
  background-color: lightgreen;
  cursor: initial !important;
  transform: rotate(-90deg);
}
.card.selected-no-rotation {
  background-color: lightgreen;
}
.card.ability-already-used {
  background-color: rosybrown;
  transform: rotate(-2deg);
}
.card.remove {
  background-color: orange;
}
.card.rotate-180deg {
  transform: rotate(180deg);
}
.card.preview-aging-card {
  background-color: darkgray;
}
.card-content-front {
  background-image: url('../../images/forest.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  height: 340px;
}
.card-content-front-first-half,
.card-content-front-second-half {
  height: 50%;
  position: relative;
}
.card-content-front-second-half {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(180deg);
}
.card-content-front-fightPoints {
  align-items: center;
  background-color: whitesmoke;
  border-radius: 50%;
  display: flex;
  font-size: 25px;
  font-weight: bold;
  height: 40px;
  justify-content: center;
  width: 40px;
}
.card-content-front-title,
.card-content-front-hazard {
  align-items: center;
  background-color: whitesmoke;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  display: flex;
  font-weight: bold;
  height: 40px;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  white-space: normal;
}
.card-content-front-hazard {
  font-size: 12px;
}
.card-content-front-highHazardValue,
.card-content-front-mediumHazardValue,
.card-content-front-lowHazardValue,
.card-content-front-freeCards {
  align-items: center;
  background-color: orange;
  border-radius: 50%;
  color: black;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  height: 30px;
  justify-content: center;
  width: 30px;
}
.card-content-front-mediumHazardValue {
  background-color: yellow;
}
.card-content-front-lowHazardValue {
  background-color: yellowgreen;
}
.card-content-front-freeCards {
  background-color: whitesmoke;
}
.card-content-front-lifePoints {
  align-items: center;
  background-color: whitesmoke;
  border-radius: 50%;
  color: black;
  display: flex;
  font-weight: bold;
  height: 40px;
  justify-content: center;
  width: 40px;
}
.card-content-front-specialAbility {
  align-items: center;
  background-color: whitesmoke;
  border-radius: 12px;
  color: black;
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 25px;
  justify-content: center;
  padding: 5px;
}
.card-content-front-specialAbility p {
  margin: auto;
}
.card-content-front-fence {
  bottom: 10px;
  position: absolute;
  width: 190px;
}
.card-content-front-fence img {
  left: -2px;
  position: relative;
  width: 100%;
}
.card-content-back {
  background-color: lightcyan;
  background-image: url('../../images/card_back.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  color: burlywood;
  height: 340px;
  position: relative;
  width: 190px;
}
.card-content-back-title {
  background-color: lightyellow;
  border-radius: 15px;
  font-size: 25px;
  font-weight: 600;
  margin: auto;
  padding: 5px;
}
