.navBar {
  padding: 3px;
  top: 0;
}
.navBar-gameOptionsModal {
  top: 20% !important;
}
.navBar-gameOptionsModal-wrapper {
  display: grid;
}
.navBar-gameOptionsModal-wrapper-headline {
  background-color: gray;
  color: white;
  padding: 5px;
  border-radius: 10px;
  margin: 10px;
}
.navBar-gameOptionsModal-wrapper-headline h1 {
  margin: 0;
  color: white;
}
.navBar-gameOptionsModal-wrapper-buttonsHolder {
  padding: 10px;
}
.navBar-gameOptionsModal .ant-modal-body {
  padding: 0px;
}
.navBar-gameOptionsModal .ant-modal-title {
  text-align: center;
}
.navBar-gameOptionsModal .resumeButton {
  color: black;
  border-bottom: 10px !important;
  background-color: mediumspringgreen;
}
.navBar-gameOptionsModal .restartButton {
  color: black;
  background-color: gray;
}
.navBar-gameOptionsModal .optionsButton {
  text-align: center;
  font-size: 25px;
  cursor: pointer;
  border: 20px solid white;
}
.navBar-lifePoints,
.navBar-agedCards,
.navBar-level,
.navBar-score,
.navBar-countdownTimer,
.navBar-hazardDiscardPile,
.navBar-robinsonDiscardPile,
.navBar-elimintaedCardsPile {
  font-weight: bold;
  font-size: 16px;
}
.navBar-lifePoints .fCenter,
.navBar-agedCards .fCenter,
.navBar-level .fCenter,
.navBar-score .fCenter,
.navBar-countdownTimer .fCenter,
.navBar-hazardDiscardPile .fCenter,
.navBar-robinsonDiscardPile .fCenter,
.navBar-elimintaedCardsPile .fCenter {
  width: 100%;
}
.cardIcon {
  border-radius: 3px;
  width: 12px;
  height: 20px;
  border: 1px solid darkgray;
}
.cardIcon.red {
  background-color: red;
}
.cardIcon.blue {
  background-color: lightskyblue;
}
.cardIcon.gray {
  background-color: gray;
}
