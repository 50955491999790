.scoring {
	height: 40px;
	width: 40px;
	background-color: white;
	border-radius: 50%;
	font-size: 20px;
	font-weight: bold;
	margin-top: 2px;
}
.pirateCard {
	background-color: whitesmoke;
	border-radius: 12px;
	height: 185px;
	overflow: auto;
	padding: 0 5px;
	transition: 0.5s ease-in-out;
	user-select: none;
	width: 350px;
}
.pirateCard.fightingNow {
	background-color: orange;
}
.pirateCard.lost {
	background-color: red;
}
.pirateCard.won {
	background-color: lightgreen;
}
.pirateCard-inside {
	width: 340px;
	height: 175px;
	background-color: white;
	border-radius: 12px;
	background-image: url('../../images/sky.png');
	background-position: center;
	background-size: contain;
}
.pirateCard-inside-freeCards {
	height: 40px;
	width: 40px;
	background-color: white;
	border-radius: 50%;
	font-size: 20px;
	font-weight: bold;
	margin-top: 2px;
	margin-left: 2px;
}
.pirateCard-inside-requiredFightScore {
	height: 40px;
	width: 40px;
	background-color: white;
	border-radius: 50%;
	font-size: 20px;
	font-weight: bold;
	margin-top: 2px;
	text-align: right;
	background-color: #ffe5b4;
	margin-left: calc(100% - 42px);
}
.pirateCard-inside-description {
	background-image: url('../../images/summer-beach.png');
	background-position: top;
	background-size: cover;
	background-repeat: no-repeat;
	height: 43px;
	border-bottom-right-radius: 12px;
	border-bottom-left-radius: 12px;
}
.pirateCard-inside-description p {
	text-align: center;
	font-size: 12px;
	font-weight: bold;
	margin: 5px;
	background-image: linear-gradient(
		to right,
		rgba(255, 255, 255, 0.4),
		rgba(255, 255, 255, 0.8)
	);
	border-radius: 5px;
	padding: 2px;
}
