.resolveSort3-ranking {
  height: 30px;
  margin-top: 5px;
}
.resolveSort3-ranking div {
  font-weight: bolder;
  font-size: 20px;
  border-radius: 10px;
  background-color: lightgreen;
}
.resolveSort3-default-ranking {
  font-weight: bold;
  font-size: 20px;
  color: lightgray;
}
